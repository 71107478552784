$(document).ready(function() {

  $('.inactive').click(function() {
    return false;
  });

  var $loadContainer    = $('.js-load-container'),
      $detailContainer  = $('.js-detail-container');

  function loadDetails() {
    $(document).on('click', '.js-load-detail', function(e) {

      var url = $(this).attr('data-fancybox-href');

      $.fancybox({
        autoSize: true,
        autoCenter: false,
        href: url + ' .js-detail',
        type: 'ajax',
        tpl: {
          closeBtn : '<a title="Close" class="fancybox-item fancybox-close" href="javascript:;"><svg viewBox="0 0 50 50"><use xlink:href="#close"></use></svg></a><a title="Close" class="fancybox-item fancybox-close button" href="javascript:;">Close</a>'
        },
        helpers : {
          overlay : {
            locked: true
          }
        }
      });
        
      e.preventDefault();
    });
  }
  loadDetails();

  
  function loadMoreClicks() {
    var $loadMore = $('.js-load-more');
    $loadMore.click(function(e) {
      $(this).fadeTo(400, 0, function() {
          $('<div class="spinner"></div>').hide().appendTo('.load-more').fadeIn(400);
          $(this).parent().parent($loadContainer).append('<div class="load-wrap flex"></div>');

          var url = $(this).attr('href'),
              $last = $('.load-wrap:last', $loadContainer);

          $last.fadeTo(0,0).load(url + ' .js-load-container  > *', function() {
            // Remove wrapping div 
            $loadMore.parent().remove();          
            $('.load-wrap .card').unwrap();
            $(this).fadeTo(400, 1);
            loadMoreClicks();
            //loadDetails();
          });
        });

        e.preventDefault();
    });
  }
  loadMoreClicks();

  // Only want to run lazy load 3 times, then stop
  var lazyLoadCalls = 0;

  function lazyLoadMore() {
    var $loadMore = $('.js-load-more');
    $loadMore.one('inview', function(event, isInView) {
        // If load more is in view, run lazy load max 3 times
        if (isInView && lazyLoadCalls < 3) {
          $(this).fadeTo(0, 0, function() {
              $('<div class="spinner"></div>').hide().appendTo('.load-more').fadeIn(400);
              $(this).parent().parent($loadContainer).append('<div class="load-wrap flex"></div>');

              var url = $(this).attr('href'),
                  $last = $('.load-wrap:last', $loadContainer);

              $last.fadeTo(0,0).load(url + ' .js-load-container  > *', function() {
                $loadMore.parent().remove();         
                $('.load-wrap .card').unwrap();
                $(this).fadeTo(400, 1);
                loadMoreClicks();
                lazyLoadMore();
              });
          });

          // Count how many times lazy load has run
          lazyLoadCalls++;

        } else {
          // do nothing
        }
    });
  }
  lazyLoadMore();

  function loadCategory() {
    var $loadMore = $('.js-load-category');
    // Load the url into the load container.
    $loadMore.click(function(e) {

      if ( $(this).hasClass('active') ) {
        return false;
      }

      var url = $(this).attr('href');

      $loadContainer.fadeTo(0,1).load(url + ' .js-load-container  > *', function() {
          $(this).fadeTo(400, 1);
          loadCategory();
       });

      e.preventDefault();
    });
  }
  loadCategory();

  // Store the data-url of the grid/list views in a variable
  var gridUrl = $('#grid-view').attr('data-url');
  var listUrl = $('#list-view').attr('data-url');
  var activeUrl = $('.view.active').attr('data-url');

  function loadAlpha() {
    var $loadMore = $('.filtered-alpha a');
    // Load the url into the load container.
    $loadMore.click(function(e) {

      if ( $(this).hasClass('active') ) {
        return false;
      }

      $('.filtered-alpha a').removeClass('active');
      $(this).addClass('active');

      // Update the grid/list view href
      var filters = [];
      var alpha = '/'+$(this).text().toLowerCase();
      $.each($('input[name="cat"]:checked'), function(){
        filters.push($(this).val());
      });

      var data = filters.join(',');
      if (filters == '') {
        var data = 'all';
      }
      $('#grid-view').attr('href', gridUrl+'/'+data+alpha);
      $('#list-view').attr('href', listUrl+'/'+data+alpha);

      var url = $(this).attr('href');

      $('<div class="spinner"></div>').hide().appendTo('.filtered').fadeIn(400);

      $loadContainer.fadeTo(0,1).load(url + ' .js-load-container  > *', function() {
          $('.spinner').remove();
          $(this).fadeTo(400, 1);
          loadAlpha();
       });

      history.pushState(null, null, url);
      //$('.options-list').attr('action', url);

      e.preventDefault();
    });
  }
  loadAlpha();
  
  function loadSemester() {
    var $loadMore = $('.js-load-semester');
    var $pagination = $('.pages');
    $loadMore.click(function(e) {

      var url = $(this).attr('href');

      $.ajax({
        url: url,
        type: 'GET',
        success: function( resp ) {
          $loadContainer.fadeTo(0,0).html($('.js-load-container', resp).html());
          $pagination.html($('.pages', resp).html());
          $loadContainer.fadeTo(400, 1);
          loadSemester();
        }
      });

      e.preventDefault();
    });
  }
  loadSemester();

  window.filterSearch = function() {
    var $form = $('.options-list');  
    var filters = [];
    $.each($('input[name="cat"]:checked'), function(){
      filters.push($(this).val());
    });

    var data = filters.join(',');
    var alpha = '';
    var url = $form.attr('action')+'/'+data;


    if ( $('.js-toggle-alpha').length ) {
      var alpha = '/'+$('.filtered-alpha a.active').text().toLowerCase();
      if (filters == '') {
        var data = 'all';
      }
      var url = $form.attr('action')+'/'+data+alpha;

      // Update alpha filter toggle href
      $('.js-toggle-alpha').attr('href', activeUrl+'/'+data);

      // Update alpha filters
      $('.filtered-alpha a').each(function() {
        var href = $(this).attr('data-url');
        var text = $(this).text().toLowerCase();
        $(this).attr('href', href+'/'+data+'/'+text);
      });
    }

    // Update the grid/list view href
    $('#grid-view').attr('href', gridUrl+'/'+data+alpha);
    $('#list-view').attr('href', listUrl+'/'+data+alpha);

    //var url = $form.attr('action')+'/'+data+alpha;
    $('<div class="spinner"></div>').hide().appendTo('.filtered').fadeIn(400);

    $loadContainer.fadeTo(0,0).load(url + ' .js-load-container  > *', function() {
      $('.spinner').remove();
      $(this).fadeTo(400, 1);
      loadMoreClicks();
      lazyLoadMore();
    });

      //console.log(url);
      history.pushState(null, null, url);
    }

  $('input[name="cat"]').click(function() {
    if ( !$(this).is(':checked') ) {
      $(this).closest('.list').find('input[name="show-all"]').prop('checked', false);
    }
  });

  $('.options-list input[name="cat"]').on('click', filterSearch);


  function showAll() {
    $('input[name="show-all"]').change(function() {
      $(this).closest('.list').find('input[name="cat"]').prop('checked', $(this).prop('checked'));
      filterSearch();
    });
  }
  showAll();

  function filteredView() {
    $('.filtered-view .view').each(function() {
      $(this).click(function() {
        var href = $(this).attr('href');
        $(this).attr('href', href+'#filter');
      });
    });
  }
  filteredView();

  $('#event-picker').datepick({
    dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    prevText: '<svg><use xlink:href="#arrow-prev"></use></svg>',
    nextText: '<svg><use xlink:href="#arrow-next"></use></svg>',
    onSelect: loadDate
  }); 

  function loadDate() {
    var $datePicker = $('#event-picker');
    var date = $.datepick.formatDate('yyyy-mm-dd', $datePicker.datepick('getDate')[0]);
    var dateClean = $.datepick.formatDate('MM dd, yyyy', $datePicker.datepick('getDate')[0]);
    var page = $datePicker.attr('data-page');
    var url = page + '/' + date;

    //history.pushState(null, null, url);

    $('<div class="spinner"></div>').hide().appendTo('.filtered').fadeIn(400);

    $loadContainer.fadeTo(0,1).load(url + ' .js-load-container  > *', function() {
      $('.spinner').remove();
      $(this).fadeTo(400, 1);
      $('.date-term').addClass('show').find('.date').text(dateClean);
      loadMoreClicks();
      lazyLoadMore();
    });
  }

});