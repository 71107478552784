$(document).ready(function() {

  // Run test on initial page load
  checkSize();

  // Run test on resize of window
  $(window).resize(checkSize);

  // Check if the reveal class has been set to hide
  function checkSize(){
    if ( $('.sr .reveal').css('visibility') == 'hidden' ){
      // Fade in
      sr.reveal('.reveal, .filtered article, .profile-feed .post, .search-results .result');

      // Sequencial Fade in
      sr.reveal('.podcasts .heading img',{
        duration: 800,
        distance: '25px',

      }, 400);

      //sr.reveal('.single-post .post-content img');
    }
  }

});