$(document).ready(function() {

    // Toggle Main Nav
    $('#main-nav-toggle').click(function() {
      $('html').toggleClass('takeover');
      $('#header').toggleClass('show');
    });

    // Toggle First Level Nav Items 
    $('.toggle').each(function() {
      $(this).click(function() {
        if ( $(this).closest('.toggle-wrap').hasClass('show') ) { 
          $(this).closest('.toggle-wrap').removeClass('show').find('.togglee').slideUp('fast');
          $('#primary-nav').removeClass('move');
        } else {
          $('#primary-nav .toggle-wrap').removeClass('show').find('.togglee').slideUp('fast');
          $('#primary-nav').removeClass('move');
          $(this).closest('.toggle-wrap').addClass('show').find('.togglee').slideDown('fast');
        }

        if ( $('.toggle-date').hasClass('active') ) {
          $('.toggle-date').removeClass('active').closest('.toggle-wrap').find('.togglee-date').slideToggle('fast');
        }
        return false;
      });
    });

    // Date Toggle
    $('.toggle-date').click(function() {
      if ( $(this).hasClass('inactive') ) {
        // Do nothing
      } else {
        if ( $(this).closest('.toggle-wrap').hasClass('show') ) {
            $(this).closest('.toggle-wrap').removeClass('show').find('.togglee').slideUp('fast');
        }
        $(this).toggleClass('active').closest('.toggle-wrap').find('.togglee-date').slideToggle('fast');
      }
      return false;
    });

    // Toggle Second Level Nav Items 
    if ( window.matchMedia('(min-width: 1200px)').matches ){
      $('.second-level-wrap .toggle-wrap > a:eq(0)').each(function() {
        $(this).click(function() {
          $(this).closest('.toggle-wrap').toggleClass('show');
          $('#primary-nav').toggleClass('move');
          return false;
        });
      });
    }

    // Show Category On Toggle
    $('.categories a.active').click(function() {
      //$('.categories a').removeClass('active');
      $(this).closest('.categories').toggleClass('show');
      return false;
    });

    // Toggle Alpha
    $('.js-toggle-alpha').click(function() {
      if ( !$(this).closest('.toggle-wrap').hasClass('show') ) {
        $(this).closest('.toggle-wrap').toggleClass('show').find('.togglee').slideToggle('fast');
        return false;
      }
    });

    // Back button
    function goBack() {
      $('.back').click(function(){
          var host = document.location.hostname;

          if(document.referrer.indexOf(host) >= 0) {
            parent.history.back();
          } else {
            window.location.href = '/';
          }
          return false;
      });
    }
    goBack();


  // Tabs
  $('.tab-nav .tab-heading').each(function() {
    $(this).click(function() {
      $(this).addClass('active').siblings().removeClass('active');
      $(this).closest('.tabs').find('.tab-content .tab').eq($(this).index()).addClass('active').siblings().removeClass('active');
      return false;
    });
  });

  $('.tab-content .tab-heading').each(function() {
    $(this).click(function() {

      $(this).closest('.tabs').find('.tab-heading').eq( $(this).closest('.tab').index() ).addClass('active').siblings().removeClass('active');

      // If this is not the active tab, do this
      if ( !$(this).closest('.tab').hasClass('active') ) {
        $('.tab').removeClass('active');
        $(this).closest('.tab').addClass('active');

        // Scroll to the top of the active tab
        var tabPosition = $(this).offset().top - 80;
        $('html, body').scrollTop(tabPosition);

      }

      return false;
    });
  });

  // Scroll to Section
  $('.js-scroll').click(function() {
    var section = $(this).attr('href');
    $('html, body').animate({
        scrollTop: $(section).offset().top
    }, 500);
  });

  // Open Alert
  // $('#alert .icon').click(function() {
  //   $('#alert').addClass('show');
  // });

  // Show More/Less
  var $more = $('.js-more');
        
  if ($more.length <= 0) {
     return false;
  }
        
  $more.click(function(e) {
    $(this).parent().toggleClass('show-all');
    e.preventDefault();
  }); 
});

$(window).scroll(function() {  
  var scroll = $(window).scrollTop();
  var header = $('#header').offset().top;
  if (scroll >= 1) {
    $('body').addClass('scroll');
  } else {
    $("body").removeClass('scroll');
  }

  if (scroll > header){ 
        $('#header').addClass('fixed'); 
    }
    else{
        $('#header').removeClass('fixed');
    }

    //console.log(scroll);
    //console.log(header);
});

// var top = $('#header').offset().top;
// $(window).scroll(function(){    
//     if ($(this).scrollTop() > top){ 
//         $('#header').addClass('fixed'); 
//     }
//     else{
//         $('#header').removeClass('fixed');
//     }
// });

