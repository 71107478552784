$(document).ready(function() {
		//
		// Slideshow Setup
		//

    var slickPrev = '<div class="slick-prev"><svg><use xlink:href="#arrow-prev"></use></svg></div>';
    var slickNext = '<div class="slick-next"><svg><use xlink:href="#arrow-next"></use></svg></div>';

      $('.dot-slideshow').slick({
        arrows: false,
        dots: true,
        fade: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 500
      });

      $('.event-slideshow').slick({
        arrows: false,
        dots: true,
        fade: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 500,
        appendDots: $('.dots'),
      });

      function slickify(){
        $('.non-mobile-slideshow').slick({
          arrows: false,
          dots: true,
          fade: true,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 5000,
          speed: 500,
          responsive: [
            {
              breakpoint: 1030,
              settings: 'unslick'
            }
          ]
        });
      }
      slickify();

      $(window).resize(function(){
        var $windowWidth = $(window).width();
        if ($windowWidth > 1029) {
            slickify();   
        }
      });

      $('.text-slideshow').slick({
        arrows: false,
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2500,
        speed: 500,
        fade: true
      });

      $('.home-slideshow').slick({
        arrows: false,
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 10000,
        pauseOnHover: false,
        speed: 900,
        fade: true
      });

      $('.posts-feed-slideshow').slick({
        arrows: true,
        infinite: false,
        autoplay: false,
        speed: 500,
        fade: true,
        nextArrow: slickNext,
        prevArrow: slickPrev,
        appendArrows: $('.arrows'),
      });

      $('.about-slideshow').slick({
        arrows: false,
        dots: true,
        infinite: false,
        autoplay: false,
        speed: 500,
        fade: true,
        appendDots: $('.dots'),
        customPaging : function(slider, i) {
          var nav = $(slider.$slides[i]).data('nav');
          return '<a href="#">'+nav+'</a>';
        },
        responsive: [
            {
              breakpoint: 600,
              settings: 'unslick'
            }
          ]
      });

      $('.mobile-slideshow').slick({
        mobileFirst: true,
        fade: true,
        arrows: false,
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        responsive: [
          {
            breakpoint: 600,
            settings: 'unslick'
          }
        ]
      });

      $('.arrow-slideshow').slick({
        arrows: true,
        dots: false,
        infinite: true,
        autoplaySpeed: 5000,
        speed: 500,
        fade: true,
        nextArrow: slickNext,
        prevArrow: slickPrev,
      });

      $('.more-posts-slideshow').slick({
        arrows: true,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: slickNext,
        prevArrow: slickPrev,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              dots: true,
              arrows: false
            }
          }
        ]
      });

      // Bookshelf Slideshow
      $('.slideshow-nav').on('init', function(event, slick){
        $('.slideshow-nav .slick-center').removeClass('turn-right').removeClass('turn-left');
        $('.slideshow-nav .slick-center').prevAll('.slick-active').addClass('turn-right').removeClass('turn-left');
        $('.slideshow-nav .slick-center').nextAll('.slick-active').addClass('turn-left').removeClass('turn-right');
        $('.slideshow-nav .slick-center').prev().prev().addClass('move-right');
        $('.slideshow-nav .slick-center').next().next().addClass('move-left');
      });

      $('.slideshow-nav').slick({
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        variableWidth: false,
        dots: false,
        infinite: true,
        autoplay: false,
        speed: 200,
        centerMode: true,
        asNavFor: '.slideshow-content',
        nextArrow: slickNext,
        prevArrow: slickPrev,
        responsive: [
          {
            breakpoint: 1029,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 599,
            settings: {
              slidesToShow: 1,
              centerMode: false,
              variableWidth: false
            }
          }
        ]
      });

      $('.slideshow-content').slick({
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        infinite: true,
        autoplay: false,
        speed: 200,
        fade: true
      });

      $('.slideshow-nav').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        var centerSlide = $(this).find('.slick-slide:not(.slick-cloned):eq('+nextSlide+')');

        $('.slideshow-nav .slide').removeClass('move-left').removeClass('move-right');

        $(centerSlide).removeClass('turn-right').removeClass('turn-left').prevAll().addClass('turn-right').removeClass('turn-left');
        $(centerSlide).prev().prev().addClass('move-right');
        $(centerSlide).nextAll().addClass('turn-left').removeClass('turn-right');
        $(centerSlide).next().next().addClass('move-left');
      });

      // History Slidshow
      $('.history-slideshow').slick({
        arrows: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: slickNext,
        prevArrow: slickPrev,
        appendDots: $('.history-timeline'),
        customPaging : function(slider, i) {
          var date = $(slider.$slides[i]).data('date');
          var show = $(slider.$slides[i]).data('show-date');
          return '<a href="#" data-date="'+date+'" class="'+show+'">'+date+'</a>';
        },
        responsive: [
          {
            breakpoint: 1030,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      });

      $('.selective-tweets-content').slick({
        arrows: false,
        dots: true,
        fade: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 500
      });

      // History Timeline
      var firstYear = $('.history-timeline li:first a').attr('data-date');
      var lastYear = $('.history-timeline li:last a').attr('data-date');
      var totalYears = lastYear - firstYear;

      historyTimeline();

      $('.history-slideshow').on('breakpoint', function(event, slick, breakpoint){
        historyTimeline();
      });

      function historyTimeline(){
        $('.history-timeline a').each(function() {
          var thisYear = $(this).attr('data-date');
          var thisYearDiff = thisYear - firstYear;
          var pos = thisYearDiff / totalYears * 100;
          $(this).css({ left: pos + '%' });
        });
      }
});