function initMap() {
        var mapelement     = document.getElementById('map');
        var varlat        = Number(mapelement.dataset.lat);
        var varlong       = Number(mapelement.dataset.long);
        var base          = mapelement.dataset.base;
        var infoWindow    = mapelement.dataset.window;
        var location      = {lat: varlat, lng: varlong};
        var mapStyle      = mapelement.dataset.style;

        if ( mapStyle == 'light' ) {
        var style    = [
            {
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#f5f5f5"
                }
              ]
            },
            {
              "elementType": "labels.icon",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#616161"
                }
              ]
            },
            {
              "elementType": "labels.text.stroke",
              "stylers": [
                {
                  "color": "#f5f5f5"
                }
              ]
            },
            {
              "featureType": "administrative.land_parcel",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#bdbdbd"
                }
              ]
            },
            {
              "featureType": "administrative.locality",
              "elementType": "labels.text",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "administrative.neighborhood",
              "elementType": "labels.text",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "landscape.natural",
              "elementType": "labels",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#eeeeee"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#757575"
                }
              ]
            },
            {
              "featureType": "poi.business",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "poi.park",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#e5e5e5"
                }
              ]
            },
            {
              "featureType": "poi.park",
              "elementType": "geometry.fill",
              "stylers": [
                {
                  "color": "#e2e6e3"
                }
              ]
            },
            {
              "featureType": "poi.park",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#9e9e9e"
                }
              ]
            },
            {
              "featureType": "poi.school",
              "elementType": "labels.text",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "poi.school",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#b5b5b5"
                }
              ]
            },
            {
              "featureType": "road",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#ffffff"
                }
              ]
            },
            {
              "featureType": "road.arterial",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#b5b5b5"
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#dadada"
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#b5b5b5"
                }
              ]
            },
            {
              "featureType": "road.local",
              "elementType": "labels.text",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "road.local",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#c0c0c0"
                }
              ]
            },
            {
              "featureType": "transit.line",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#e5e5e5"
                }
              ]
            },
            {
              "featureType": "transit.station",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#eeeeee"
                }
              ]
            },
            {
              "featureType": "water",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#cdd2d4"
                }
              ]
            },
            {
              "featureType": "water",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#9e9e9e"
                }
              ]
            }
          ];
        } else {
        var style         = [ 
            { 
              featureType: "landscape", 
              elementType: "geometry", 
              stylers: [{ color: "#e8e5dd" }] 
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry',
              stylers: [{color: '#b5dcc6'}]
            },
            {
              featureType: 'poi.attraction',
              elementType: 'geometry',
              stylers: [{color: '#b5dcc6'}]
            }
          ];
        }

        var map           = new google.maps.Map(mapelement, {
          zoom: 15,
          center: location,
          mapTypeControl: false,
          streetViewControl: false,
          scrollwheel: false,
          styles: style
        });

        var urlBase = '/'+base+'/images/';

        if ( infoWindow == 'true' ) {
          var image = {
            url: urlBase + 'window-marker.png',
            scaledSize: new google.maps.Size(32, 6)
          };
        } else {
          var image = {
            url: urlBase + 'map-marker.png',
            scaledSize: new google.maps.Size(32, 48)
          };
        }
        var marker = new google.maps.Marker({
          position: location,
          map: map,
          icon: image
        });

        var infowindow = new google.maps.InfoWindow({
          content: document.getElementById('map-info'),
        });

        if ( infoWindow == 'true' ) {
          google.maps.event.addListenerOnce(map, 'tilesloaded', function() {
            infowindow.open(map, marker);
            map.panBy(0, -100);
          });
        }
}