$(document).ready(function() {

  var $radio = $('input[type="radio"]'),
      $checkbox = $('input[type="checkbox"]'),
      $form = $('.form-container');
  

  $form.each(function() {
    $('input[type="checkbox"],input[type="radio"').change(function() {
      var id = $(this).attr('id'),
          $label = $('label[for="'+id+'"]');
        if ($(this).is(':checked')) {
          // yesh here
          $label.addClass('checked');
        } else {
          $label.removeClass('checked');
        }
    });
  });  
});