$(document).ready(function () {

  // Toggle Courses
	$('.course-toggle').each(function () {
		$(this).click(function () {
			$(this).closest('.toggle-wrap').toggleClass('show').find('.course-togglee').slideToggle('fast');
		});
	});

	// Show Category On Toggle
	$('.categories a.active').click(function () {
		//$('.categories a').removeClass('active');
		$(this).closest('.categories').toggleClass('show');
		return false;
	});

	// Back button
	function goBack() {
		$('.back').click(function () {
			var host = document.location.hostname;

			if (document.referrer.indexOf(host) >= 0) {
				parent.history.back();
			} else {
				window.location.href = '/';
			}
			return false;
		});
	}
	goBack();

});
